<template>
    <div id="pageList" class="WayBillList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">运单编号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.billCode = validForbid(e)" placeholder="请输入运单编号进行模糊匹配查询..." v-model="pageList.queryParam.billCode" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">项目：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('saleBillId',el)" v-model="pageList.queryParam.saleBillId" placeholder="请选择项目" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'saleBillId'})" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">要求装货时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.loadTime" placeholder="请选择要求装货时间"/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">要求送货时间：</el-col>
                    <el-col :span="9">
                        <el-date-picker v-model="pageList.queryParam.deliveryTime" placeholder="请选择要求送货时间"/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerId = validForbid(e)" placeholder="请输入客户进行模糊匹配查询..." v-model="pageList.queryParam.customerId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">装货点：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.customerAddressId = validForbid(e)" placeholder="请输入装货点进行模糊匹配查询..." v-model="pageList.queryParam.customerAddressId" clearable/>
                    </el-col>
                </el-row>

                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">销售类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('saleType',el)" v-model="pageList.queryParam.saleType" placeholder="请选择销售类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'saleType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">委托单号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.authCode = validForbid(e)" placeholder="请输入委托单号进行模糊匹配查询..." v-model="pageList.queryParam.authCode" clearable/>
                    </el-col>
                </el-row>

                <el-row class="myRow">
<!--                    <el-col :span="3" class="myColTitle">运单状态：</el-col>-->
<!--                    <el-col :span="9">-->
<!--                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择运单状态" clearable style="width:100%">-->
<!--                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>-->
<!--                        </el-select>-->
<!--                    </el-col>-->
                    <el-col :span="3" class="myColTitle">需求类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="pageList.queryParam.type" placeholder="请选择需求类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>
            <!--重写表格固定列-->
            <template v-slot:fixCol>
                <el-table-column fixed="left" header-align="center" align="center" label="操作" width="260">
                    <template #default="scope">
                        <div style="display: flex">
                            <el-button @click="pageListRef.editHandler(scope.row)" type="text">
                                <i class="el-icon-edit"></i>&nbsp;&nbsp;详情
                            </el-button>
                            <div v-if="scope.row['F_OVER_PC']==0">
                                <el-button @click="pc(scope.row)" type="text">
                                    <div style="color: #27ae60">
                                        <i class="iconfont icon-cheliangzhuanru"></i>&nbsp;&nbsp;立即派车
                                    </div>
                                </el-button>
                                <el-button @click="overPc(scope.row)" type="text">
                                    <div style="color: #f39c12">
                                        <i class="iconfont icon-cheliangleixing"></i>&nbsp;&nbsp;结束派车
                                    </div>
                                </el-button>
                            </div>
                            <el-button @click="pageListRef.deleteHandler(scope.row)" type="text">
                                <div style="color:red">
                                    <i class="el-icon-delete"></i>&nbsp;&nbsp;删除
                                </div>
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_CODE" label="运单编号" width="180">
                    <template #default="scope">
                        <el-tag style="cursor: pointer" @click="pageListRef.editHandler(scope.row)">{{ scope.row.F_CODE }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="F_CUSTOMER_ID" label="客户"/>
                <el-table-column prop="F_SALE_BILL_NAME" label="项目"/>
                <el-table-column prop="F_SALE_TYPE" label="销售类型"/>
                <el-table-column prop="F_BEGAN_DATE" label="合同开始日期"/>
                <el-table-column prop="F_END_DATE" label="合同结束日期">
                    <template #default="scope">
                        <!--如果合同签约结束日期大于当前日期还未派车，那么把合同结束日期显示为红色-->
                        <span style="color: red" v-if="scope.row.F_END_DATE<today && scope.row['F_STATUS']==0">{{ scope.row.F_END_DATE }}</span>
                        <span v-else="scope.row.F_END_DATE>today">{{ scope.row.F_END_DATE }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="F_LOAD_TIME" label="要求装货时间"/>
                <el-table-column prop="F_DELIVERY_TIME" label="要求送货时间"/>
                <el-table-column prop="F_CUSTOMER_ADDRESS_ID" label="装货点"/>
                <el-table-column prop="F_TYPE" label="需求类型"/>
                <el-table-column prop="F_AUTH_CODE" label="委托单号"/>
                <el-table-column prop="F_COLLECTION_MONEY" label="代收货款"/>
<!--                <el-table-column prop="F_STATUS" label="运单状态"/>-->
                <el-table-column prop="F_TIME" label="创建时间"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import WayBillCard from './WayBillCard';
    import WayBillSendCar from './sendCar/WayBillSendCar';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "WayBillList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                today:utils.$$str.formatDate(new Date()),
                pageList: {
                    queryParam: {
                       billCode:"", 
                       saleBillId:"", 
                       loadTime:"", 
                       deliveryTime:"",
                        customerId:"",
                        customerAddressId:"",
                       type:"",
                        saleType:'',
                       status:"",
                       authCode:""
                    },
                    modelComp: WayBillCard,
                    modelMethod: "/wayBill/pageData"
               }
            })
            onMounted(async ()=>{
                await buildSaleBillData();
            })
            //---------------------------computed------------
            const buildSaleBillData=async ()=>{
                dataObj.saleBillData= await utils.$$api.getProjectSaleBills({});
            }
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'saleBillId'==params.comboId){
                        return dataObj.saleBillData;
                    }

                    if(params && 'saleType'==params.comboId){
                        return [{value:0,label:'项目'},{value:1,label:'临时'}]
                    }
                    if(params && 'type'==params.comboId){
                        return [{value:1,label:'车'},{value:2,label:'货'}]
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'待派车'},{value:1,label:'待装货'},{value:2,label:'运输中'},{value:3,label:'已签收'}]
                    }
                }
            })
            const selectOnChange=(newValue,selectId)=> {

            }
            const pc=async (row)=>{
                if(row.F_END_DATE<dataObj.today){
                    proxy.$message('已过合同有效期，不能派车了');
                }else{
                    let options = {
                        from:'WayBillList',
                        wayBillId:row['F_ID'],
                        wayBillCode:row['F_CODE'],
                        saleBillId:row['F_SALE_BILL_ID'],
                        modelComp: WayBillSendCar,
                        ownerComp:proxy,
                        title:"派车配置",
                        dialogDiv:"WayBillCarDiv",
                        contentHeight:200,
                        fullscreen:true
                    }
                    let vm = await utils.$$dialog.create(options);
                    vm.dialogVisible = true;
                }
            }
            const overPc=async (row)=>{
                utils.$$tools.configBox({
                    msgContent:'确认执行该操作吗？',
                    fn:async ()=> {
                        let res = await utils.$$api.postRequest({ url:'/wayBill/custom', params: {id:row['F_ID'],operateType:'overPc'}});
                        if(res.result){
                            utils.$$tools.success({ message: res.msg });
                            dataObj.pageListRef.queryHandler();
                        }
                    }
                });
            }
            return{
                ...toRefs(dataObj),comboSelect,selectOnChange,pc,overPc
            }
        }
    });
</script>

<style scoped>
    .WayBillList{
        width: 100%;
    }
</style>