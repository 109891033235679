<template>
    <div class="wayBillSendCar">
        <GridTable :ref="el=>refMap.set('gridRef1',el)" v-bind="gridParam">
            <template v-slot:_tbCols>
                <el-table-column prop="F_TIME" label="派车时间" sortable='true'/>
                <el-table-column prop="F_CAR_ORDER" label="车次号"/>
                <el-table-column prop="F_CX_NAME" label="车型"/>
                <el-table-column prop="F_CAR_NUM" label="车牌号"/>
                <el-table-column prop="F_DRIVER_NAME" label="司机"/>
                <el-table-column prop="F_STATUS_NAME" label="状态"/>
                <el-table-column prop="F_LOAD_TIME" label="要求装货时间"/>
                <el-table-column prop="F_DELIVERY_TIME" label="要求送货时间"/>
                <el-table-column prop="F_CUSTOMER_ID" label="客户"/>
                <el-table-column prop="F_SALE_BILL_ID" label="项目"/>
                <el-table-column prop="F_CODE" label="运单编号"/>
                <el-table-column prop="F_AUTH_CODE" label="委托单号"/>
            </template>
        </GridTable>
        <el-row style="margin-top: 20px;">
            <el-button type="primary" @click="chooseProjectCar" v-if="gridParam.queryParam.saleBillId">选择项目车辆</el-button>
            <el-button type="success" @click="chooseCar">选择全部车辆</el-button>
        </el-row>
        <el-table :data="tableData" style="width: 100%;margin-top: 20px">
            <el-table-column fixed="left" label="操作" width="120">
                <template #default="scope">
                    <el-button @click="deleteHandler(scope.row)" type="text">
                        <div style="color:red"><i class="el-icon-delete"/>&nbsp;&nbsp;删除</div>
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column prop="F_CAR_ORDER" label="车次号"/>
            <el-table-column prop="F_CX_ID" label="车型"/>
            <el-table-column prop="F_CAR_NUM" label="车牌号"/>
            <el-table-column prop="F_CYS_ID" label="承运商"/>
            <el-table-column prop="F_CITY_ID" label="所在城市"/>
            <el-table-column prop="F_FLEET_NAME" label="关联车队"/>
            <el-table-column prop="F_DRIVER_ID" label="默认司机"/>
            <el-table-column prop="F_COOPERATION_TYPE" label="合作形式"/>
        </el-table>
        <el-row class="surePcBtn">
            <el-button type="warning" @click="surePcHandler">确定派车</el-button>
        </el-row>
    </div>
</template>

<script>
    import {
        ref,
        reactive,
        toRefs,
        computed,
        defineComponent,
        onMounted,
        getCurrentInstance,
        watch,
        provide,
        inject
    } from 'vue';
    import CarList from "../../../ylgl/car/CarList";
    export default defineComponent ({
        name: "WayBillCar",
        props: {
            engineParams: {
                type: Object
            }
        },
        setup(props,context) {
            let dialogInst = inject('dialogInst');
            const {proxy} = getCurrentInstance();
            const utils = proxy.utils;
            provide('gridTableOwner', proxy);
            let dataObj = reactive({
                refMap: new Map(),
                tableData:[],
                gridParam: {
                    title:'已派车次',
                    canPage:false,
                    isShowIndexCol:false,
                    queryParam: {
                        from:props.engineParams?props.engineParams.from:'',
                        wayBillId:props.engineParams?props.engineParams.wayBillId:'',
                        wayBillCode:props.engineParams?props.engineParams.wayBillCode:'',
                        saleBillId:props.engineParams?props.engineParams.saleBillId:'',
                    },
                    modelMethod: "/wayBill/pageData"
                }
            })
            onMounted(async () => {})
            //检查准备添加的车辆是否已经生成过车次了
            const checkExisted=(id)=>{
                let data=dataObj.refMap.get('gridRef1').getTbData();
                for(let i=0;i<data.length;i++){
                    if(data[i]['F_ID']==id && data[i]['F_STATUS']!=3){
                        return true;
                    }
                }
                return false;
            }
            //检查是否已经添加了指定车辆到待派车列表
            const checkExist=(id)=>{
                for(let i=0;i<dataObj.tableData.length;i++){
                    if(dataObj.tableData[i]['F_ID']==id){
                        return true;
                    }
                }
                return false;
            }
            //选择车辆弹出框确认事件
            const sureHandler=async (modelInst)=>{
                let selectRows = modelInst.pageListRef.getTbInst().getSelection();
                if(selectRows.length>0){
                    let noteDoublePc='';
                    let noteFlag=false;
                    for(let i=0;i<selectRows.length;i++){
                        // if(!checkExisted(selectRows[i]['F_ID'])){
                        //     if(!checkExist(selectRows[i]['F_ID']))dataObj.tableData.push(selectRows[i]);
                        // }else{
                        //     noteFlag=true;
                        //     noteDoublePc=noteDoublePc+selectRows[i]['F_CAR_NUM']+",";
                        // }
                        if(!checkExist(selectRows[i]['F_ID'])){
                            dataObj.tableData.push(selectRows[i]);
                        }else{
                            noteFlag=true;
                            noteDoublePc=noteDoublePc+selectRows[i]['F_CAR_NUM']+",";
                        }
                    }
                    if(noteFlag){
                        noteDoublePc=noteDoublePc.substr(0,noteDoublePc.length-1);
                        utils.$$tools.info({ message: noteDoublePc+'已经被派车成功' });
                    }
                }
                let start=dataObj.refMap.get('gridRef1').getTbData().length;
                for(let i=0;i<dataObj.tableData.length;i++){
                    dataObj.tableData[i]['F_CAR_ORDER']=dataObj.gridParam.queryParam.wayBillCode+'-'+(start+1+i);
                }
                return true;
            }
            const chooseProjectCar=async ()=>{
                await utils.$$lghdUtils.buildCommonDialog({from:'wayBillCar',saleBillId:dataObj.gridParam.queryParam.saleBillId,title: "选择项目车辆",dialogDiv:"CarList",
                    modelComp: CarList,proxy:proxy});
            }
            const chooseCar=async ()=>{
                await utils.$$lghdUtils.buildCommonDialog({from:'wayBillAllCar',saleBillId:dataObj.gridParam.queryParam.saleBillId,title: "选择车辆",dialogDiv:"CarList",
                    modelComp: CarList,proxy:proxy});
            }
            //确认派车
            const surePcHandler=()=>{
                if(dataObj.tableData.length==0){
                    proxy.$message.info("请选择车辆");
                }else{
                    utils.$$tools.configBox({
                        msgContent:'确认执行该操作嘛?',
                        fn:async ()=> {
                            let carIds=[];
                            let flag=false;
                            for(let i=0;i<dataObj.tableData.length;i++){
                                if(!dataObj.tableData[i]['F_DRIVER_ID']){
                                    proxy.$message.info("第"+(i+1)+"行车辆，没有配置司机，请配置司机");
                                    flag=true;break;
                                }
                                carIds.push(dataObj.tableData[i]['F_ID']+'###'+dataObj.tableData[i]['F_CAR_ORDER']);
                            }
                            if(!flag){
                                let params={carIds:carIds,wayBillId:dataObj.gridParam.queryParam.wayBillId,operateType:'pc'}
                                let res = await utils.$$api.postRequest({ url: '/wayBill/custom', params: params});
                                if(res.result)utils.$$tools.success({ message: '操作成功' });
                                dialogInst.dialogVisible=false;
                            }
                        }
                    });
                }
            }
            //删除已选的车辆
            const deleteHandler=(row)=>{
                let id=row['F_ID'];
                for(let i=0;i<dataObj.tableData.length;i++){
                    if(dataObj.tableData[i]['F_ID']==id){
                        dataObj.tableData.splice(i,1);break;
                    }
                }
            }
            return{
                ...toRefs(dataObj),sureHandler,chooseProjectCar,chooseCar,surePcHandler,deleteHandler
            }
        }
    })
</script>

<style scoped>
.wayBillSendCar{}
    .surePcBtn{
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
</style>