<template>
    <div id="pageList" class="CarList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">车型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cxId',el)" v-model="pageList.queryParam.cxId" clearable placeholder="请选择车型" style="width: 100%" @change="(val)=>{selectOnChange(val,'cxId')}">
                            <el-option v-for="item in cxData" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车牌号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.carNum = validForbid(e)" placeholder="请输入车牌号进行模糊匹配查询..." v-model="pageList.queryParam.carNum" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">承运商：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.cysId = validForbid(e)" placeholder="请输入承运商进行模糊匹配查询..." v-model="pageList.queryParam.cysId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所在城市：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="pageList.queryParam.cityId" placeholder="请选择所在城市" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">关联车队：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.fleetId = validForbid(e)" placeholder="请输入车队进行模糊匹配查询..." v-model="pageList.queryParam.fleetId" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">默认司机：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.driverId = validForbid(e)" placeholder="请输入默认司机进行模糊匹配查询..." v-model="pageList.queryParam.driverId" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">年检时间：</el-col>
                    <el-col :span="9">
                       <el-date-picker v-model="pageList.queryParam.njTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">保险时间：</el-col>
                    <el-col :span="9">
                       <el-date-picker v-model="pageList.queryParam.bxTime" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">车架号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.vin = validForbid(e)" placeholder="请输入车架号进行模糊匹配查询..." v-model="pageList.queryParam.vin" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">所有人：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.owner = validForbid(e)" placeholder="请输入所有人进行模糊匹配查询..." v-model="pageList.queryParam.owner" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">道路运输许可证：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.xkz = validForbid(e)" placeholder="请输入道路运输许可证进行模糊匹配查询..." v-model="pageList.queryParam.xkz" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">合作形式：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('cooperationType',el)" v-model="pageList.queryParam.cooperationType" placeholder="请选择合作形式" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'cooperationType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">手机号：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.phone = validForbid(e)" placeholder="请输入手机号进行模糊匹配查询..." v-model="pageList.queryParam.phone" clearable/>
                    </el-col>
                </el-row>
            </template>
            <!--重写表格固定列-->
            <template v-slot:fixCol v-if="engineParams">
                <el-table-column fixed="left" type="selection" width="55"/>
                <el-table-column fixed="left" header-align="center" align="center" label="操作" width="150">
                    <template #default="scope">
                        <el-button @click="pageListRef.editHandler(scope.row)" type="text">
                            <i class="el-icon-edit"></i>&nbsp;&nbsp;详情
                        </el-button>
                        <el-button @click="chooseDriver(scope.row)" type="text">
                            <div style="color: #27ae60">
                                <i class="el-icon-s-flag"></i>&nbsp;&nbsp;{{scope.row['F_DRIVER_ID']?'更改司机':'添加司机'}}
                            </div>
                        </el-button>
                    </template>
                </el-table-column>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_CX_ID" label="车型"/>
                <el-table-column prop="F_CAR_NUM" label="车牌号"/>
                <el-table-column prop="F_CYS_ID" label="承运商"/>
                <el-table-column prop="F_CITY_ID" label="所在城市"/>
                <el-table-column prop="F_FLEET_NAME" label="关联车队"/>
                <el-table-column prop="F_DRIVER_ID" label="默认司机"/>
                <el-table-column prop="F_NJ_TIME" label="年检时间"/>
                <el-table-column prop="F_BX_TIME" label="保险时间"/>
                <el-table-column prop="F_VIN" label="车架号"/>
                <el-table-column prop="F_OWNER" label="所有人"/>
                <el-table-column prop="F_XKZ" label="道路运输许可证" width="100"/>
                <el-table-column prop="F_COOPERATION_TYPE" label="合作形式"/>
                <el-table-column prop="F_STATUS" label="审核状态"/>
                <el-table-column prop="F_FLAG" label="是否停用"/>
            </template>
        </ListEngine>
    </div>
</template>

<script>
    import CarCard from './CarCard';
    import DriverList from '../driver/DriverList';
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "CarList",
        props: {
            engineParams: {
                type: Object
            }
        },
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                cityData:[],
                cxData:[],
                pageList: {
                    queryParam: {
                       cxId:"", 
                       carNum:"", 
                       cysId:"", 
                       cooperationType:"", 
                       cityId:"", 
                       fleetId:"", 
                       njTime:"", 
                       bxTime:"", 
                       vin:"", 
                       owner:"", 
                       xkz:"", 
                       driverId:"", 
                       status:"", 
                       flag:"",
                        from:props.engineParams?props.engineParams.from:'',//运力配置那里配置项目车辆，弹出框传过来的参数from=’ylpz‘
                        saleBillId:props.engineParams?props.engineParams.saleBillId:''//运力配置那里配置项目车辆，弹出框传过来的参数saleBillId，其值等于要配置项目车辆的销售单ID
                    },
                    modelComp: CarCard,
                    modelMethod: "/car/pageData"
               }
            })
            onMounted(async ()=>{
                await buildCxData();
                await buildCityData();
            })
            const selectOnChange=async(newValue,selectId)=>{

            }
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'cxId'==params.comboId){
                        return dataObj.cxData;
                    }
                    if(params && 'cooperationType'==params.comboId){
                        return [{value:0,label:'自有车辆'},{value:1,label:'合同车辆（速享所有）'}]
                    }

                    if(params && 'cityId'==params.comboId){
                        return dataObj.cityData;
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:0,label:'待渠道审核'},{value:1,label:'渠道已拒绝'},{value:2,label:'待平台审核'},{value:3,label:'已通过审核'},{value:4,label:'平台已拒绝'}]
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:0,label:'启用'},{value:1,label:'禁用'}]
                    }
                }
            })

            const buildCityData=async ()=>{
                dataObj.cityData = await utils.$$api.getCitiesByChannelId({});
            }
            const buildCxData=async (params)=>{
                dataObj.cxData= await utils.$$api.getCx(params);
            }

            const sureHandler=async (modelInst)=>{
                let selectRows = modelInst.pageListRef.getTbInst().getSelection();
                if(selectRows.length>0){
                    utils.$$tools.configBox({
                        msgContent:'确定执行该操作吗?',
                        fn:async ()=> {
                            let carId=modelInst.pageList.queryParam.carId;
                            let url = "/fleet/custom";
                            let res = await utils.$$api.postRequest({url: url, params: {operateType:'addOrModifyDriver',carId:carId,driverId: selectRows[0]['F_ID']}});
                            if(res.result){
                                dataObj.pageListRef.queryHandler();
                                utils.$$tools.success();
                            }
                        }
                    });
                }
                return true;
            }
            const chooseDriver=async (row)=>{
                await utils.$$lghdUtils.buildCommonDialog({from:'carList',fleetId:row['F_FLEET_ID'],carId:row['F_ID'],title: "选择司机",dialogDiv:"DriverList",
                    modelComp: DriverList,proxy:proxy});
            }
            return{
                ...toRefs(dataObj),comboSelect,selectOnChange,sureHandler,chooseDriver
            }
        }
    });
</script>

<style scoped>
    .CarList{
        width: 100%;
    }
</style>